import request from "../utils/request";

/**
 * 生成馬丁策略
 */
import {change_url} from "./robot";
import method from "vue-resource/src/http/interceptor/method";
export const userCreateMartin=(robotType,quoteCoin,baseCoin,strategyType,trendCycle,firstSpace,cycleNumber,firstMultiple,billNumber,stopRatio,stopCallback,trackStop,replenishmentConf,replenishmentCallback,trackReplenishment,fullStop,fullStopRatio,fullSpace,trendCycleSwitch)=>request({
    method:'POST',
    url:'/martin/addMartinStrategy',
    params:{
        robotType,       //交易所  0币安，1 OKEX
        quoteCoin,         //计价币种  start
        baseCoin,       //交易币种
        strategyType,        //策略类型：0仓位马丁策略，1盈利预期马丁策略，2仓位&盈利预期双马丁策略
        trendCycle,         //趋势周期
        firstSpace,         //首单額度
        cycleNumber,          // 循环次数：0无限
        firstMultiple,      //首单倍数
        billNumber,           //  做单數量
        stopRatio,          //止盈比例

        stopCallback,       //止盈回调
        trackStop,          // 止盈回調：0开启，1关闭             止盈回調的關閉

        replenishmentConf,      //补仓配置

        replenishmentCallback,      //补仓回调
        trackReplenishment,     // 補倉回調：0开启，1关闭         補倉回調的關閉

        fullStop,           // 满单止损：0开启，1关闭
        fullStopRatio,      //满单止损比例


        fullSpace,                  //满单仓位
        trendCycleSwitch           //shifou kaiqi 0 kai 1guan
    }
})


/**
 * 修改馬丁策略
 */
export const userAlterMartin=(robotType,quoteCoin,baseCoin,strategyType,trendCycle,firstSpace,cycleNumber,firstMultiple,billNumber,stopRatio,stopCallback,trackStop,replenishmentConf,replenishmentCallback,trackReplenishment,fullStop,fullStopRatio,fullSpace,uid,trendCycleSwitch)=>request({
        method:'POST',
        url:'/martin/updateMartinStrategy',
        params:{
            robotType,       //交易所  0币安，1 OKEX
            quoteCoin,         //计价币种  start
            baseCoin,       //交易币种
            strategyType,        //策略类型：0仓位马丁策略，1盈利预期马丁策略，2仓位&盈利预期双马丁策略
            trendCycle,         //趋势周期
            firstSpace,         //首单額度
            cycleNumber,          // 循环次数：0无限
            firstMultiple,      //首单倍数
            billNumber,           //  做单數量
            stopRatio,          //止盈比例

            stopCallback,       //止盈回调
            trackStop,          // 止盈回調：0开启，1关闭             止盈回調的關閉

            replenishmentConf,      //补仓配置

            replenishmentCallback,      //补仓回调
            trackReplenishment,     // 補倉回調：0开启，1关闭         補倉回調的關閉

            fullStop,           // 满单止损：0开启，1关闭
            fullStopRatio,      //满单止损比例


            fullSpace,                  //满单仓位
            uid,
            trendCycleSwitch
        }
    })

/**
 * 獲取交易對信息
 */
export const getCoinInfo=(coin)=>request({
    method:'get',
    url:'/okex/getAllSymbolsByCoin?coin=usdt',
    params:{coin}
})

